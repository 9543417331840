.navbarWrapper{
	padding: 4px 0;
	// border-bottom: 1px solid #0000001a;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	background-color: #fffffff2;
	z-index: 99;
	height: 57px;
	display: flex;
	align-items: center;
}

.navLogo{
  font-weight: 600;
  font-size: 26px;
}
.navLogo:hover{
	opacity: .75;
}

.flawVersion {
  padding-left: 8px;
  font-size: .7rem;
  color: #006EFF;
	font-weight: lighter;
}

.innerNavbar{
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 17px;
}

.innerNavbar button{
	font-size: 30px;
}

.sunnyButton {
	height: 30px;
	width: 30px;
}

.rightSideNav{
	display: flex;
	align-items: center;
	justify-content: center;
}

.sunIcon{
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sunIcon:hover{
	opacity: .75;
	transition: .15s;
}

.mobileMenuBtn{
	transition: .15s;
}
.mobileMenuBtn:hover{
	opacity: .7;
}
