.avatarDiv{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.avatarDivLeft{
	display: flex;
	align-items: center;
	font-size: 18px;
}

.userCard{
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px 0 10px;
}

.whiteTopShaddow{
	height: 30px;
	background-image: linear-gradient(#fff 0%, #fff0 100%);
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 99;
}

.whiteBottomShaddow{
	height: 30px;
	background-image: linear-gradient(#fff0 0%, #fff 100%);
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 99;
}

.progressionDiv{
	margin-left: 30px;
}

.progressionDiv h6{
	font-size: 20px;
	margin: 0;
}

.progressionDiv p{
	opacity: .5;
}

.progressWrapper{
	display: flex;
	align-items: center;
}

.cardLink {
  color: #000;
}
.cardLink:hover{
	color: #000;
}




.rollingWrapper{
	height: 550px;
	overflow: hidden;
}

.letsRoll {
	animation: rollingDiv 7s linear infinite;
}

@keyframes rollingDiv {
	0% {
		transform:translateY(0)
	}
	to {
		transform:translateY(calc(-100% - 20px))
	}
}


.rollingDiv{
	composes: letsRoll;
}

.rollingDiv2{
	composes: letsRoll;
}
