.customThemeList p {
  padding-left: .5rem;
  font-weight: 400;
  // opacity: .65;
}

.customThemeList code {
  opacity: 1 !important;
}

.customThemeList li {
    padding-bottom: .5rem;
}

.customThemeList {
  font-weight: 700;
}
