.showCodeDiv {
  border: 1px solid #e8e8e8;
  // background: #fdfdfd;
  border-radius: 4px;
  overflow-x: scroll;
  margin: 2rem 0;
	padding: 0;
}

.showCodeDiv code {
  font-size: 14px !important;
	font-family: "Consolas", "Monaco", "Andale Mono", "Ubuntu Mono", "monospace";
  line-height: 1.6;
}
