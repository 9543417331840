.footerWrapper{
	font-size: 15px;
	min-height: 50px;
	display: flex;
	align-items: center;
}

.footerLeft a{
	margin-right: 10px;
}

.footerRight a {
  color: #000;
}

.footerLeft{
	flex-wrap: wrap;
  display: flex;
}

.coderName{
	transition: .15s;
}
.footerRight a:hover .coderName {
  color: #006eff !important;
}

.innerFooter{
	display: flex;
	justify-content: space-between;
	align-items: center;
	// flex-wrap: wrap;
}

@media (max-width: 575.98px){
	.innerFooter{
		flex-direction: column;
		padding: 20px 0;
	}

	.footerLeft{
		flex-direction: column;
		align-items: center;
	}

	.footerLeft a{
		margin-bottom: 20px;
	}

	.footerRight{
		padding-top: 10px;
	}
}
