.documentationWrapper{
	border: 1px solid #0002;
	width: calc(100vw - 250px);
	height: calc(100vh - 64px - 50px);
	max-height: calc(100vh - 64px - 50px);
	min-height: 300px;
	padding: 0 12px 12px 12px;
	overflow: scroll;
}

.documentationAndMenu{
	display: flex;
}

@media (max-width: 767.98px){
	.documentationWrapper{
		width: 100vw;
		overflow: scroll;
		max-height: fit-content;
		height: auto;
	}

	.desktopMenu{
		display: none;
	}
}
