.copyInstallBtn {
  border: none;
  font-size: 1rem;
  padding: 0;
  width: 100%;
  text-align: left;
  outline: none;
  background: none;
}

.terminalCode {
  background-color: #f2f4f5;
  padding: .4rem 1rem;
  margin: 1rem 0;
  border-radius: 4px;
  position: relative;
	cursor: pointer;
}

.clickToCopyText, .codeToCopy {
  transition: .25s linear;
}

.clickToCopyText {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  font-size: .8rem;
  opacity: 0;
  color: #006eff;
  padding: 0 1rem;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.terminalCode:hover .clickToCopyText {
  opacity: 1 !important;
}

.terminalCode:hover .codeToCopy {
  filter: blur(4px);
  opacity: .3;
}
