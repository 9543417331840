.errorPageWrapper{
	min-height: calc(100vh - 64px - 50px);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-bottom: 150px;
}

.error404{
	font-size: 230px;
	font-weight: bold;
	opacity: .1;
}

.errorTitle{
	position: absolute;
	bottom: 60px;
	font-weight: bold;
	font-size: 32px;
}

.errorMsg{
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.errorPar{
	margin-top: -30px;
	opacity: .7;
}
