body {
	font-family: 'Source Sans Pro', sans-serif;
	margin: 0;
	padding: 0;
}

a {
  color: #006EFF;
  background-color: initial;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: .15s;
}
a:hover{
	color: #3b90ff;
}

p {
  line-height: 1.8;
}

.transparentBtn{
	border: none;
	background: none;
	cursor: pointer;
	padding: 0;
	outline: none;
}

.componentTitle{
	font-size: 2rem;
}

.fadeIn{
	animation: fadeIn .25s forwards;
}

code{
	background-color: #ecf0ff;
	padding: .1rem .3rem;
	border-radius: .2rem;
	color: #1900ec;
	font-family: 'Source Sans Pro', sans-serif;
}

@keyframes fadeIn {
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}
