.apiTitle {
	margin-top: 0;
  font-size: 2rem;
}

.tableProperty{
	color: #1890b8;
}

.tableType {
  color: #2f9c0a;
}
