.iconBtnDiv {
  display: flex;
  justify-content: center;
  margin: 1rem 0px;
}

.iconBtn {
  height: 100px;
  width: 100px;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  transition: .2s;
  font-size: 35px;
	display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iconBtn:hover {
  background-color: rgba(0,110,255,.07);
}

.iconName {
  padding-top: 4px;
  opacity: .5;
  font-size: 10px;
}
