.displayCol{
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: .3rem 0;
  color: #fff;
  font-size: 1.1rem;
}

.displayBgCol{
  background-color: rgba(0,0,0,.07);
  border-radius: 4px;
}

.rowOverlay{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.blueCol{
  background-color: rgba(0,110,255,.67);
  border-radius: 4px;
}

.customThemeList p {
  padding-left: .5rem;
  font-weight: 400;
  // opacity: .65;
}

.customThemeList code {
  opacity: 1 !important;
}

.customThemeList li {
    padding-bottom: .5rem;
}

.customThemeList {
  font-weight: 700;
}
