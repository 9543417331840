#carbonads {
  display: block;
  overflow: hidden;
  padding: 10px 0;
  // box-shadow: 0 1px 3px hsla(0, 0%, 0%, .05);
	// border: 1px solid rgba(0, 0, 0, 0.133);
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.5;
  max-width: 300px;
  font-size: 12px;
  background-color: #fff;
	animation: .3s fadeIn;
	// background-color: #f2f2f2;
}

#carbonads a:hover{
	color: #637381;
}

#carbonads a {
  text-decoration: none;
}

#carbonads span {
  position: relative;
  display: block;
  overflow: hidden;
}

.carbon-img {
  float: left;
  margin-right: 1em;
}

.carbon-img img {
  display: block;
}

.carbon-text {
  display: block;
  float: left;
  max-width: calc(100% - 130px - 1em);
  text-align: left;
  color: #637381;
}

.carbon-poweredby {
  position: absolute;
  left: 142px;
  bottom: 0;
  display: block;
  font-size: 8px;
  color: #c5cdd0;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 1px;
}

.carbon-adds-wrapper{
	height: 120px;
	width: 300px;
  margin-top: 20px;
	// background-color: #f2f2f2;
	background-color: #fff;
}
