.homeImgAnim{
	opacity: 0;
	animation: .5s homeImgFadeIn forwards;
}

.homeTitleAnim{
	opacity: 0;
	animation: .5s homeImgFadeIn .2s forwards;
}

.homeTextAnim{
	opacity: 0 !important;
	animation: .5s homeTextFadeIn .5s forwards;
}

.homeBtnAnim{
	opacity: 0;
	animation: .5s homeFadeIn .8s forwards;
}


@keyframes homeImgFadeIn {
	0%{
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes homeFadeIn {
	0%{
		opacity: 0;
		transform: translateY(10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes homeTextFadeIn {
	0%{
		opacity: 0;
		transform: translateY(10px);
	}
	100% {
		opacity: .6;
		transform: translateY(0);
	}
}
