.componentsMenu * {
	font-size: 15px !important;
}

.componentsMenu{
	overflow: scroll;
  height: calc(100vh - 64px - 50px);
  min-height: 300px;
}

.newTag{
	font-size: 12px!important;
	margin-left: 20px !important;
	cursor: pointer !important;
	line-height: 16px !important;
}


.componentsMenu a{
	// justify-content: space-between;
}
