.presentationWrapper{
	min-height: 100%;
}

.presentationLeft{
	min-height: calc(100vh - 64px);
	display: flex;
  flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 80px;
}

.presentationLeft img{
	height: 240px;
}

.homepageLogoName{
	font-size: 50px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 20px;
}

.presentationLeft p{
	margin: 10px 0 20px 0;
	opacity: .5;
}

.presentationBtns{
	display: flex;
}

.homeImgDiv{
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.homeImg{
	height: 100%;
}

.homePagePar{
	opacity: .6;
}

.homePageText{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	height: 100%;
}

.tutorialsWrapper{
	padding: 8rem 0;
}

.tutorialsWrapper h3{
	font-size: 40px;
}

.newsletterInputDiv{
	width: 100%;
	padding-bottom: 10px;
}

.mainDetailsWrapper{
	padding: 6rem 0;
}

.detailWrapper{
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 0 10px;
	margin-bottom: 30px;
}

.detailTitle{
	font-size: 35px;
	margin: 0;
	color: #006eff;
}

.detailWrapper p{
	margin-top: 2px;
	opacity: .6;
	font-size: 18px;
}

.docButton{
  padding: 0 0 120px 0;
	display: flex;
	justify-content: center;
}

.reactLogoImg{
	animation: rotate 5s infinite linear;
}

@keyframes rotate {
	0%{
		transform: rotate(0deg);
	}
	100%{
		transform: rotate(360deg);
	}
}



@media (max-width: 575.98px) {
	.presentationLeft{
		padding-bottom: 10px;
	}

	.presentationLeft img{
		height: 150px;
	}

	.homepageLogoName{
		font-size: 40px;
	}

	.presentationLeft p{
		font-size: 14px;
	}

	.presentationBtns{
		flex-direction: column;
		align-items: center;
	}

	.homeImg{
    width: 50%;
	}

	.tutorialsWrapper{
		padding: 0;
	}

	.newsletterWrapper{
		padding: 100px 0 120px !important;
	}

	.homePageText{
		align-items: center;
	}

	.homePagePar{
		text-align: center;
	}

	.homeImgDiv{
		height: 200px;
	}
}

@media (max-width: 767.98px) {
	.newsletterDiv .homePageText{
		padding-top: 40px;
	}

	.mainTutorialWrapper .homePageText{
		padding-top: 40px;
	}

	.mainDetailsWrapper{
		padding-top: 0;
	}

	.mainTutorialWrapper{
		flex-direction: column-reverse;
	}
}
